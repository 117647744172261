<template lang="pug">
  div.login-container
    el-card.login-card
      div.logo-container
        span.logo iLop
        span 直播间
      el-form.form-container(:model="loginForm" :rules="rules" ref="loginForm")
        el-form-item(prop="userId")
          el-input(
            v-model="loginForm.userId"
            placeholder="请输入账号"
            ref="userId"
          )
        el-tooltip(v-model="capsTooltip" content="大写锁定已开启" placement="right" manual)
          el-form-item(prop="password")
            el-input(
              v-model="loginForm.password"
              type="password"
              @keyup.native="checkCapsLock"
              @blur="capsTooltip = false"
              placeholder="请输入密码"
            )
        el-form-item
          el-button.login-button(
            type="primary"
            @click="handleLogin"
          ) 登录
</template>

<script>
export default {
  name: 'Login',
  data() {
    const validateUserId = (rule, value, callback) => {
      !value && callback(new Error('账号必填'));
      callback();
    };
    const validatePassword = (rule, value, callback) => {
      !value && callback(new Error('密码必填'));
      callback();
    };
    return {
      loginForm: {
        userId: '',
        password: '',
      },
      rules: {
        userId: [{ validator: validateUserId, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
      },
      capsTooltip: false,
    };
  },
  mounted() {
    if (!this.$isMobile) {
      this.$refs.userId.focus();
    }
  },
  methods: {
    checkCapsLock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z');
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('login', this.loginForm)
            .then(() => {
              this.$router.push({ path: '/' });
            })
            .catch((err) => {
              this.$message.error(err);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.login-container
  width 100%
  height 100%
  position relative
  display flex
  justify-content center
  align-items center
  .login-card
    position relative
    width 400px
    height 360px
    .logo-container
      margin 36px 0 32px 0
      display flex
      align-items center
      justify-content center
      font-size 26px
      .logo
        color #0076ff
        font-size 32px
        font-family 'Times New Roman'
        font-weight 700
        margin-right: 10px
    .form-container
      width: 80%
      margin 0 auto
      .login-button
        margin-top 20px
        width 100%
</style>

